const practicalRouter = {
    route: '/practical',
    name: null,
    title: '实操文件',
    type: 'view', // 类型: folder, tab, view
    name: Symbol('practical'),
    icon: 'iconfont icon-tushuguanli',
    filePath: 'view/practical/practical.vue', // 文件路径
    order: 1,
    inNav: true,
    // permission: ['查询'],
  }
  
  export default practicalRouter